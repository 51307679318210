








































































































































































































































































































































import SelectMechanic from "@/components/custom/select/SelectMechanic.vue";
import { debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { DataListMasterAsset } from "@/models/interface/assets.interface";
import { BranchWarehouseParams } from "@/models/interface/branch";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataResponseGetListWorkOrder,
  DataResponseGetListWorkOrderHeader,
  DataResponseReportWorkOrderResult,
  DataResponseWorkOrderResult,
  DataWarehouseBranch,
} from "@/models/interface/logistic.interface";
import { assetsServices } from "@/services/assets.service";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { currencyFormat } from "@/validator/globalvalidator";
import { FooterCreateInternalContract } from "@/views/sales/internal-contract/CreateInternalContract.vue";
import moment from "moment";
import printJs from "print-js";
import Vue from "vue";

interface LoadingComponent {
  loadingCustomerName: boolean;
  loadingUnitCode: boolean;
  loadingWoNumber: boolean;
  loadingFind: boolean;
  loadingCustomerMaintenance: boolean;
  loadingCsfNumber: boolean;
  loadingBranch: boolean;
  download: boolean;
  print: boolean;
}

export default Vue.extend({
  name: "CustomerMaintenance",
  components: {
    SelectMechanic,
  },
  mixins: [MNotificationVue],
  data() {
    this.getListCustomerMaintenanceNumber = debounceProcess(
      this.getListCustomerMaintenanceNumber,
      200
    );
    this.getCustomerName = debounceProcess(this.getCustomerName, 200);
    this.getUnitCode = debounceProcess(this.getUnitCode, 200);
    this.getWoNumber = debounceProcess(this.getWoNumber, 200);
    this.getListCsfNumber = debounceProcess(this.getListCsfNumber, 200);
    this.getBranch = debounceProcess(this.getBranch, 200);
    return {
      dataFinish: [
        { key: "ALL", value: "All" },
        { key: "YES", value: "Yes" },
        { key: "NO", value: "No" },
      ],
      moment: moment,
      DEFAULT_DATE_FORMAT,
      form: this.$form.createForm(this, { name: "customerMaintenance" }),
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      finish: false as boolean,
      params: {
        sorts: "completeDate:desc",
        showPrice: true,
      } as RequestQueryParamsModel,
      totalData: 0 as number,
      loading: {
        loadingCustomerName: false,
        loadingUnitCode: false,
        loadingWoNumber: false,
        loadingFind: false,
        loadingCustomerMaintenance: false,
        loadingCsfNumber: false,
        download: false,
        print: false,
        loadingBranch: false,
      } as LoadingComponent,
      footerCreateInternalContract: {
        total: 0,
      } as FooterCreateInternalContract,
      columnsTable: [
        {
          title: this.$t("lbl_no"),
          dataIndex: "no",
          key: "no",
          width: 70,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_result_wo_no"),
          dataIndex: "resultWorkOrderNo",
          key: "resultWorkOrderNo",
          width: 200,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_date_result_wo"),
          dataIndex: "completeDate",
          key: "completeDate",
          width: 200,
          scopedSlots: { customRender: "isDate" },
        },
        {
          title: this.$t("lbl_csf_no"),
          dataIndex: "workOrderCsfNo",
          key: "workOrderCsfNo",
          width: 200,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_wo_no"),
          dataIndex: "workOrderNo",
          key: "workOrderNo",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_wo_date"),
          dataIndex: "workOrderDate",
          key: "workOrderDate",
          width: 150,
          scopedSlots: { customRender: "isDate" },
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          key: "customerName",
          width: 200,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_mechanic"),
          dataIndex: "mechanic",
          key: "mechanic",
          width: 200,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_part_no"),
          dataIndex: "productCode",
          key: "productCode",
          width: 250,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_part_name"),
          dataIndex: "productName",
          key: "productName",
          width: 250,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_uom"),
          dataIndex: "uom",
          key: "uom",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_asset_location"),
          dataIndex: "assetLocation",
          key: "assetLocation",
          width: 250,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_qty_wo"),
          dataIndex: "qtyWO",
          key: "qty",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_cogs"),
          dataIndex: "cogsWO",
          key: "cogs",
          width: 150,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: this.$t("lbl_qty_wo_cogs"),
          dataIndex: "totalCogsWo",
          key: "totalCogsWo",
          width: 150,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: this.$t("lbl_qty_result"),
          dataIndex: "qtyResult",
          key: "qtyResult",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_qty_result_cogs"),
          dataIndex: "totalCogsResult",
          key: "totalCogsResult",
          width: 150,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "status",
          key: "status",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_reason"),
          dataIndex: "reason",
          key: "reason",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_note"),
          dataIndex: "notes",
          key: "notes",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },

        {
          title: this.$t("lbl_action"),
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
          button: ["view"],
          width: 120,
          align: "center",
        },
      ],
      formRules: {
        customerMaintenanceNumber: {
          label: "lbl_customer_maintenance_number",
          name: "customerMaintenanceNumber",
          placeholder: "lbl_customer_maintenance_number_placeholder",
          decorator: [
            "customerMaintenanceNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        finish: {
          label: "lbl_finish_customer_maintenance",
          name: "finish",
          placeholder: "lbl_finish_customer_maintenance_placeholder",
          decorator: ["finish"],
        },
        totalQtyWo: {
          label: "lbl_total_qty_wo",
          name: "totalQtyWo",
          placeholder: "lbl_total_qty_wo",
          decorator: ["totalQtyWo"],
        },
        totalCogs: {
          label: "lbl_total_cogs",
          name: "totalCogs",
          placeholder: "lbl_total_cogs",
          decorator: ["totalCogs"],
        },
        totalQtyWoxCogs: {
          label: "lbl_total_qty_wo_cogs",
          name: "totalQtyWoxCogs",
          placeholder: "lbl_total_qty_wo_cogs",
          decorator: ["totalQtyWoxCogs"],
        },
        totalQtyResult: {
          label: "lbl_total_qty_result",
          name: "totalQtyResult",
          placeholder: "lbl_total_qty_result",
          decorator: ["totalQtyResult"],
        },
        totalQtyResultxCogs: {
          label: "lbl_total_qty_result_cogs",
          name: "totalQtyResultxCogs",
          placeholder: "lbl_total_qty_result_cogs",
          decorator: ["totalQtyResultxCogs"],
        },
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "lbl_branch_placeholder",
          decorator: [
            "branch",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_customer_name_placeholder",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        woNumber: {
          label: "lbl_work_order_number",
          name: "woNumber",
          placeholder: "lbl_work_order_number_placeholder",
          decorator: [
            "woNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        csfNumber: {
          label: "lbl_csf_number",
          name: "csfNumber",
          placeholder: "lbl_csf_number_placeholder",
          decorator: [
            "csfNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        unitCode: {
          label: "lbl_unit_code",
          name: "unitCode",
          placeholder: "lbl_unit_code_placeholder",
          decorator: [
            "unitCode",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        date: {
          label: "lbl_date",
          name: "date",
          placeholder: "lbl_date_placeholder",
          decorator: [
            "date",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        dateFrom: {
          label: "lbl_date_from",
          name: "dateFrom",
          placeholder: "lbl_date_from_placeholder",
          decorator: [
            "dateFrom",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        dateTo: {
          label: "lbl_date_to",
          name: "dateTo",
          placeholder: "lbl_date_to_placeholder",
          decorator: ["dateTo"],
        },
        mechanic: {
          label: "lbl_mechanic_name",
          name: "mechanic",
          placeholder: "lbl_mechanic_name",
          decorator: ["mechanic"],
        },
      },
      dataSource: [] as DataResponseReportWorkOrderResult[],
      dataCustomerName: [] as ContactData[],
      dataBranch: [] as DataWarehouseBranch[],
      dataUnitCode: [] as DataListMasterAsset[],
      dataWoNumber: [] as DataResponseGetListWorkOrderHeader[],
      dataListCustomerMaintenance: [] as DataResponseWorkOrderResult[],
      dataCsfNumber: [] as DataResponseGetListWorkOrder[],
      woNumber: "" as string,
    };
  },
  methods: {
    handleDownload() {
      this.form.validateFields((err, res) => {
        if (err) return;
        this.loading.download = true;
        this.params.params = this.checkParams(res);
        this.params.search = this.dynamicSearch(res);
        this.params.resultWo = res.finish;
        this.params.sorts = "workOrderDate:desc";
        logisticServices
          .getDownloadResultdWorkOrder(this.params)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "report_customer_maintenance.xlsx"); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .finally(() => (this.loading.download = false));
      });
    },
    handlePrint() {
      this.form.validateFields((err, res) => {
        if (err) return;
        this.loading.print = true;
        this.params.resultWo = res.finish;
        this.params.params = this.checkParams(res);
        this.params.search = this.dynamicSearch(res);
        logisticServices
          .getPrintResultdWorkOrder(this.params)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response]));
              printJs(url);
            }
          })
          .finally(() => (this.loading.print = false));
      });
    },
    responseViewTable(response) {
      if (!response.data.resultWorkOrderId) {
        this.showNotifInfo("notif_work_order_not_finish_yet");
        return;
      }

      this.$router.push(
        `/logistic/workorder/result/view/${response.data.resultWorkOrderId}`
      );
    },
    handleWorkOrderNumber(value) {
      this.woNumber = value;
      this.getListCsfNumber("");
      this.form.resetFields(["csfNumber"]);
    },
    getListCustomerMaintenanceNumber(valueSearch) {
      let params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if (valueSearch) params.search = `resultWorkOrderNo~*${valueSearch}*`;
      this.loading.loadingCustomerMaintenance = true;
      logisticServices
        .getListResultWorkOrder(params)
        .then(response => (this.dataListCustomerMaintenance = response.data))
        .finally(() => (this.loading.loadingCustomerMaintenance = false));
    },
    getBranch(valueSearch) {
      const params = new BranchWarehouseParams();
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      this.loading.loadingBranch = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => {
          this.dataBranch = response.data;
        })
        .finally(() => (this.loading.loadingBranch = false));
    },
    getCustomerName(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: "customer~true_AND_active~true",
      };
      if (valueSearch)
        params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
      this.loading.loadingCustomerName = true;
      contactServices
        .listContactData(params)
        .then(response => (this.dataCustomerName = response.data))
        .finally(() => (this.loading.loadingCustomerName = false));
    },
    getUnitCode(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if (valueSearch) params.search = `unitCode~*${valueSearch}*`;
      this.loading.loadingUnitCode = true;
      assetsServices
        .listMasterAsset(params)
        .then(response => (this.dataUnitCode = response.data))
        .finally(() => (this.loading.loadingUnitCode = false));
    },
    getWoNumber(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if (valueSearch) params.search = `documentNumber~*${valueSearch}*`;
      this.loading.loadingWoNumber = true;
      logisticServices
        .getListWorkOrderHeader(params)
        .then(response => (this.dataWoNumber = response.data))
        .finally(() => (this.loading.loadingWoNumber = false));
    },
    getListCsfNumber(valueSearch) {
      if (this.woNumber) {
        let params = {
          page: 0,
          limit: 10,
          search: `workOrder.documentNumber~*${this.woNumber}*`,
        } as RequestQueryParamsModel;
        if (valueSearch) params.csfNo = `${valueSearch}`;
        this.loading.loadingCsfNumber = true;
        logisticServices
          .getListWorkOrder(params)
          .then(response => {
            this.dataCsfNumber = response.data;
            this.dataCsfNumber = this.dataCsfNumber.filter(
              (data, index, self) =>
                index ===
                self.findIndex(t => t.workOrderCSFId === data.workOrderCSFId)
            );
          })
          .finally(() => (this.loading.loadingCsfNumber = false));
      } else {
        this.$notification.error({
          message: "Error",
          description: "Choose WO Number first to get csf number",
        });
      }
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.onSubmit(true);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.onSubmit(true);
    },
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      // company is mandatory
      res.csfNumber = undefined;
      res.partNumber = undefined;
      params += this.$store.state.authStore.authData.companyName;
      params += this.checkValue(
        `,${
          this.dataBranch.find(dataFind => dataFind.id === res["branch"])?.name
        }`
      );
      params += this.checkValue(`,${res["customerMaintenanceNumber"]}`);
      params += this.checkValue(`,${res["woNumber"]}`);
      params += this.checkValue(`,${res["csfNumber"]}`);
      params += this.checkValue(
        `,${this.dataCustomerName
          .find(dataFind => dataFind.id === res["customerName"])
          ?.fullName?.replace(",", "")}`
      );
      const mechanicName = res && res.mechanic ? res.mechanic.label : "ALL";
      params += `,${mechanicName}`;
      params += this.checkValue(`,${res["unitCode"]}`);
      params += this.checkValue(`,${res["partNumber"]}`);
      params += this.checkValue(
        res["date"] && res["date"].length > 0
          ? `,${moment(res["date"][0]).format(DEFAULT_DATE_FORMAT)}`
          : null
      );
      params += this.checkValue(
        res["date"] && res["date"].length > 0
          ? `,${moment(res["date"][1]).format(DEFAULT_DATE_FORMAT)}`
          : null
      );
      params += this.checkValue(`,${res["finish"]}`);
      return params;
    },
    assignSearch(key, value, and): string {
      if (key === "customerName" && value) return and + `customerId~${value}`;
      else if (key === "mechanic" && value && value.key)
        return and + `mechanicId~${value.key}`;
      else if (key === "unitCode" && value) return and + `unitCode~${value}`;
      else if (key === "branch" && value) return and + `branchId~${value}`;
      else if (key === "woNumber" && value) return and + `workOrderNo~${value}`;
      else if (key === "customerMaintenanceNumber" && value)
        return and + `resultWorkOrderNo~${value}`;
      else if (key === "csfNumber" && value)
        return and + `workOrderCsfNo~${value}`;
      else if (key === "date" && value && value.length > 0)
        return (
          and +
          `workOrderDate>=${moment(value[0])
            .set({ hour: 0, minute: 0, second: 0 })
            .utcOffset("+07")
            .format()}_AND_` +
          `workOrderDate<=${moment(value[1])
            .set({ hour: 23, minute: 59, second: 59 })
            .utcOffset("+07")
            .format()}`
        );
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    setDataForm(values): void {
      for (const key in values) {
        this.form.getFieldDecorator(key, {
          initialValue: values[key],
        });
      }
      this.form.setFieldsValue(values);
    },
    onSubmit(page): void {
      this.form.validateFields((err, values) => {
        if (!page) this.page = 1;
        if (err) return;
        const params: RequestQueryParamsModel = {
          page: this.page - 1,
          limit: this.limit,
          sorts: "workOrderDate:desc",
          showPrice: true,
          resultWo: values.finish,
        };
        if (this.dynamicSearch(values))
          params.search = this.dynamicSearch(values);
        this.loading.loadingFind = true;
        logisticServices
          .getListReportResultWorkOrder(params)
          .then(response => {
            this.footerCreateInternalContract.total = currencyFormat(
              response.totalPrice
            );
            this.totalData = response.totalElements;
            this.params.params = this.checkParams(values);
            this.setDataForm({
              totalQtyWo: currencyFormat(response.totalQtyWo),
              totalCogs: currencyFormat(response.totalCogs),
              totalQtyWoxCogs: currencyFormat(response.totalCogsWo),
              totalQtyResult: currencyFormat(response.totalQtyResult),
              totalQtyResultxCogs: currencyFormat(response.totalCogsRwo),
            });
            this.dataSource = response.data.map((dataMap, key) => {
              return {
                ...dataMap,
                key,
                no: response.currentPage * this.limit + (key + 1),
                completeDate: dataMap.completeDate
                  ? moment(dataMap.completeDate).format("DD-MMM-yyyy HH:MM:ss")
                  : "",
                priceTotal: currencyFormat(dataMap.priceTotal),
                children: dataMap.productPrices?.map(
                  (dataMapChildren, keyChildren) => {
                    return {
                      ...dataMapChildren,
                      cogs: currencyFormat(dataMapChildren.cogs),
                      qty: currencyFormat(dataMapChildren.qty),
                      priceTotal: currencyFormat(dataMapChildren.priceTotal),
                      key: `${key}.${keyChildren}`,
                    };
                  }
                ),
              };
            });
          })
          .finally(() => (this.loading.loadingFind = false));
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 10 },
        wrapperCol: { span: 12 },
      };
    },
  },
  created() {
    this.getCustomerName("");
    this.getUnitCode("");
    this.getWoNumber("");
    this.getListCustomerMaintenanceNumber("");
    this.getBranch("");
  },
  mounted() {
    this.setDataForm({
      finish: "ALL",
    });
  },
});
