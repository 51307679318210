













































































































































































































import { SearchBuilder } from "@/builder";
import SelectMechanic from "@/components/custom/select/SelectMechanic.vue";
import { setNumbering } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import { useBlob, useDate } from "@/hooks";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataResponseGetListWorkOrder,
  DataResponseWorkOrderCsf,
  DataWarehouseBranch,
} from "@/models/interface/logistic.interface";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import moment from "moment";
import printJs from "print-js";
import Vue from "vue";

export default Vue.extend({
  name: "ListCSF",
  components: {
    SelectMechanic,
  },
  data() {
    this.getListCsfNumber = debounceProcess(this.getListCsfNumber, 200);
    this.getListStatus = debounceProcess(this.getListStatus, 200);
    this.getBranch = debounceProcess(this.getBranch, 200);
    return {
      DEFAULT_DATE_FORMAT,
      form: this.$form.createForm(this, { name: "listCSF" }),
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      params: {} as RequestQueryParamsModel,
      totalData: 0 as number,
      loading: {
        loadingFind: false,
        loadingCsfNumber: false,
        print: false,
        loadingStatus: false,
        loadingDocumentNumber: false,
        loadingBranch: false,
        download: false,
      },
      columnsTable: [
        {
          title: this.$t("lbl_number_short"),
          dataIndex: "no",
          key: "no",
          width: 75,
          scopedSlots: { customRender: "no" },
        },
        {
          title: this.$t("lbl_csf_number"),
          dataIndex: "csfNo",
          key: "csfNo",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_wo_number"),
          dataIndex: "workOrderNumber",
          key: "workOrderNumber",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_wo_date"),
          dataIndex: "workOrderDate",
          key: "workOrderDate",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_mechanic_name"),
          dataIndex: "mechanic",
          key: "mechanic",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "status",
          key: "status",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_action"),
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
          button: ["print"],
          width: 120,
          align: "center",
        },
      ],
      formRules: {
        csfNumber: {
          label: "lbl_csf_number",
          name: "csfNumber",
          placeholder: "Select CSF Number",
          decorator: [
            "csfNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        mechanic: {
          label: "lbl_mechanic_name",
          name: "mechanicName",
          placeholder: "lbl_mechanic_name",
          decorator: ["mechanic"],
        },
        status: {
          label: "lbl_status",
          name: "status",
          placeholder: "Select Status",
          decorator: [
            "status",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        dateFrom: {
          label: "lbl_date_from",
          name: "dateFrom",
          placeholder: "Select Date From",
          decorator: [
            "dateFrom",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        dateTo: {
          label: "lbl_date_to",
          name: "dateTo",
          placeholder: "Select Date To",
          decorator: [
            "dateTo",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        workOrderNumber: {
          label: "lbl_wo_number",
          name: "workOrderNumber",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "workOrderNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "Insert Branch",
          decorator: [
            "branch",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      dataStatus: [] as ResponseListMaster[],
      dataSource: [] as DataResponseWorkOrderCsf[],
      dataCsfNumber: [] as DataResponseWorkOrderCsf[],
      dataBranch: [] as DataWarehouseBranch[],
      dataDocumentNumber: [] as DataResponseGetListWorkOrder[],
      woNumber: "" as string,
    };
  },
  methods: {
    getListDocumentNumber(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search = `workOrder.documentNumber~*${valueSearch}*`;
      this.loading.loadingDocumentNumber = true;
      logisticServices
        .getListWorkOrder(params)
        .then(
          response =>
            (this.dataDocumentNumber = response.data.filter(
              (v, i, a) =>
                a.findIndex(t => t.workOrderId === v.workOrderId) === i
            ))
        )
        .finally(() => (this.loading.loadingDocumentNumber = false));
    },
    responseViewTable(response) {
      logisticServices.getListWorkOrderCsfPrint(response.data.id).then(data => {
        const url = window.URL.createObjectURL(new Blob([data]));
        printJs(url);
      });
    },
    getBranch(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      this.loading.loadingBranch = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => {
          this.dataBranch = response.data;
        })
        .finally(() => (this.loading.loadingBranch = false));
    },
    getListCsfNumber(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `documentNumber~*${valueSearch}*`;
      this.loading.loadingCsfNumber = true;
      logisticServices
        .getListWorkOrderCsf(params)
        .then(response => (this.dataCsfNumber = response.data))
        .finally(() => (this.loading.loadingCsfNumber = false));
    },
    getListStatus(valueSearch) {
      const params: RequestQueryParamsModel = {
        name: "WORK_ORDER_CSF_STATUS",
        page: 0,
        limit: 10,
      };
      if (valueSearch) params.search = `value~*${valueSearch}*`;
      this.loading.loadingStatus = true;
      masterServices.listMaster(params).then(response => {
        this.dataStatus = response;
      });
      this.loading.loadingStatus = false;
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.onSubmit(true);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.onSubmit(true);
    },
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      // company is mandatory
      params += this.$store.state.authStore.authData.companyName;
      params += this.checkValue(
        `,${
          this.dataBranch.find(dataFind => dataFind.id === res["branch"])?.name
        }`
      );
      params += this.checkValue(`,${res["customerMaintenanceNumber"]}`);
      params += this.checkValue(`,${res["woNumber"]}`);
      params += this.checkValue(`,${res["csfNumber"]}`);
      params += this.checkValue(`,${res["customerName"]}`);
      params += this.checkValue(`,${res["unitCode"]}`);
      params += this.checkValue(
        res["dateFrom"]
          ? `,${moment(res["dateFrom"]).format("DD-MMM-yyyy")}`
          : null
      );
      params += this.checkValue(
        res["dateTo"] ? `,${moment(res["dateTo"]).format("DD-MMM-yyyy")}` : null
      );

      return params;
    },
    assignSearch(key, value, and): string {
      if (key === "csfNumber" && value)
        return and + `documentNumber~*${value}*`;
      else if (key === "status" && value) return and + `status~${value}`;
      else if (key === "dateFrom" && value)
        return (
          and +
          `workOrder.workOrderDate>=${moment(value)
            .set({ hour: 0, minute: 0, second: 0 })
            .utcOffset("+07")
            .format()}`
        );
      else if (key === "dateTo" && value)
        return (
          and +
          `workOrder.workOrderDate<=${moment(value)
            .set({ hour: 23, minute: 59, second: 59 })
            .utcOffset("+07")
            .format()}`
        );
      else if (key === "workOrderNumber" && value)
        return and + `workOrder.documentNumber~*${value}*`;
      else if (key === "branch" && value)
        return and + `workOrder.branch.secureId~${value}`;
      else if (key === "mechanic" && value && value.key)
        return and + `workOrder.mechanic.secureId~${value.key}`;
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    onSubmit(pagination = false): void {
      this.form.validateFields((err, values) => {
        if (err) return;
        if (!pagination) {
          this.page = 1;
        }
        const params: RequestQueryParamsModel = {
          page: this.page - 1,
          limit: this.limit,
          sorts: "workOrder.workOrderDate:desc",
        };
        params.search = this.dynamicSearch(values);
        this.loading.loadingFind = true;
        logisticServices
          .getListWorkOrderCsf(params)
          .then(response => {
            this.totalData = response.totalElements;
            this.params.params = this.checkParams(values);
            this.dataSource = response.data.map((dataMap, i) => {
              return {
                ...dataMap,
                workOrderDate: moment(dataMap.workOrderDate).format(
                  "DD-MM-yyyy"
                ),
                no: setNumbering(response.currentPage, this.limit, i),
              };
            });
          })
          .finally(() => (this.loading.loadingFind = false));
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    buildQuerySearchDownload(form): string {
      const { toStartDay, toEndDay } = useDate();
      const queries: string[] = [];

      if (form.dateFrom) {
        const findByWoDate = new SearchBuilder()
          .push(["workOrderDate", toStartDay(form.dateFrom).format()], {
            het: true,
          })
          .build();
        queries.push(findByWoDate);
      }

      if (form.dateTo) {
        const findByWoDate = new SearchBuilder()
          .push(["workOrderDate", toEndDay(form.dateTo).format()], {
            let: true,
          })
          .build();
        queries.push(findByWoDate);
      }

      if (form.csfNumber) {
        const findByCsfNumber = new SearchBuilder()
          .push(["csfNumber", form.csfNumber], { like: "both" })
          .build();
        queries.push(findByCsfNumber);
      }

      if (form.status) {
        const findByStatus = new SearchBuilder()
          .push(["csfStatus", form.status], { like: "both" })
          .build();
        queries.push(findByStatus);
      }

      if (form.mechanic && form.mechanic.key) {
        const findByMechanic = new SearchBuilder()
          .push(["mechanicId", form.mechanic.key])
          .build();
        queries.push(findByMechanic);
      }

      if (form.workOrderNumber) {
        const findByWoNumber = new SearchBuilder()
          .push(["workOrderNumber", form.workOrderNumber], { like: "both" })
          .build();
        queries.push(findByWoNumber);
      }

      if (form.branch) {
        const findByBranch = new SearchBuilder()
          .push(["branchId", form.branch])
          .build();
        queries.push(findByBranch);
      }

      return queries.join(new SearchBuilder().AND);
    },
    buildDownloadParams(): RequestQueryParamsModel {
      const { toDefaultFormat, toStartDay, toEndDay } = useDate();
      const form = this.form.getFieldsValue();
      const params = new RequestQueryParams();

      params.limit = this.totalData;
      params.sorts = "workOrderDate:desc";
      params.search = this.buildQuerySearchDownload(form);

      const branch =
        this.dataBranch.find(e => e.id === form.branch)?.name ?? "ALL";
      const mechanic = form.mechanic?.label ?? "ALL";

      let woDate = "ALL";
      if (form.dateTo && form.dateFrom) {
        woDate = `${toDefaultFormat(
          toStartDay(form.dateFrom)
        )} to ${toDefaultFormat(toEndDay(form.dateTo))}`;
      } else if (form.dateTo) {
        woDate = `ALL to ${toDefaultFormat(toEndDay(form.dateTo))}`;
      } else if (form.dateFrom) {
        woDate = `${toDefaultFormat(toStartDay(form.dateTo))} to ALL`;
      }

      const header: string[] = [
        this.$store.state.authStore.authData.companyName,
        branch,
        form.workOrderNumber ?? "ALL",
        form.csfNumber ?? "ALL",
        woDate,
        mechanic,
      ];
      params.params = header.join(",");

      return params;
    },
    async handleDownload() {
      const { toDownload } = useBlob();
      try {
        this.loading.download = true;
        const res = await logisticServices.downloadCsfList(
          this.buildDownloadParams()
        );
        toDownload(res, "list_of_csf.xlsx");
      } finally {
        this.loading.download = false;
      }
    },
    handleReset() {
      this.form.resetFields();
    },
  },
  created() {
    this.getListStatus("");
    this.getListCsfNumber("");
    this.getListDocumentNumber("");
    this.getBranch("");
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
