var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: this.$t("lbl_csf_list") } },
    [
      _c(
        "a-form",
        _vm._b(
          {
            attrs: { layout: "vertical", form: _vm.form, labelAlign: "left" },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit(false)
              }
            }
          },
          "a-form",
          _vm.formItemLayout,
          false
        ),
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.dateFrom.label) } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.dateFrom.decorator,
                            expression: "formRules.dateFrom.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          placeholder: _vm.$t(
                            _vm.formRules.dateFrom.placeholder
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.dateTo.label) } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.dateTo.decorator,
                            expression: "formRules.dateTo.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          placeholder: _vm.$t(_vm.formRules.dateTo.placeholder)
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.csfNumber.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.csfNumber.decorator,
                              expression: "formRules.csfNumber.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.csfNumber.name,
                            placeholder: _vm.$t(
                              _vm.formRules.csfNumber.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loading.loadingCsfNumber,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListCsfNumber(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataCsfNumber, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.csfNo } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s("" + data.csfNo) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.csfNo) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.status.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.status.decorator,
                              expression: "formRules.status.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.status.name,
                            placeholder: _vm.$t(
                              _vm.formRules.status.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loading.loadingStatus,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListStatus(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataStatus, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.value } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s("" + data.value) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.value) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t(_vm.formRules.mechanic.label)
                      }
                    },
                    [
                      _c("SelectMechanic", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.mechanic.decorator,
                            expression: "formRules.mechanic.decorator"
                          }
                        ],
                        attrs: {
                          placeholder: _vm.$t(
                            _vm.formRules.mechanic.placeholder
                          ),
                          "label-in-value": ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t(_vm.formRules.workOrderNumber.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.workOrderNumber.decorator,
                              expression: "formRules.workOrderNumber.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.workOrderNumber.name,
                            placeholder: _vm.$t(
                              _vm.formRules.workOrderNumber.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loading.loadingDocumentNumber,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListDocumentNumber(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataDocumentNumber, function(data, index) {
                          return _c(
                            "a-select-option",
                            {
                              key: index,
                              attrs: { value: data.workOrderNumber }
                            },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s("" + data.workOrderNumber) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s("" + data.workOrderNumber) +
                                      " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t(_vm.formRules.branch.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branch.decorator,
                              expression: "formRules.branch.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.branch.name,
                            placeholder: _vm.$t(
                              _vm.formRules.branch.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loading.loadingBranch,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getBranch(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleReset } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "html-type": "submit",
                            type: "primary",
                            loading: _vm.loading.loadingFind
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("TableCustom", {
                attrs: {
                  dataSource: _vm.dataSource,
                  columns: _vm.columnsTable,
                  paginationcustom: true,
                  defaultPagination: false,
                  "row-key": "id",
                  scroll: { x: "calc(700px + 50%)", y: 700 }
                },
                on: { "on-print": _vm.responseViewTable }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.totalData,
                  pageSizeSet: _vm.limit,
                  idPagination: "pagination1"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "a-tag",
                {
                  staticStyle: { "font-size": "13px" },
                  attrs: { color: "grey" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("lbl_total_found")) +
                      " : " +
                      _vm._s(_vm.totalData) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-3 text-right" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading.download },
              on: { click: _vm.handleDownload }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }